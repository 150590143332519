<template>
  <v-card>
    <v-card-title>Appointment</v-card-title>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="4"
            sm="12"
          >
            <p class="mt-5 OVERLINE">
              Booking by
            </p>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="appointmentForm.booked_by"
                  :error-messages="errors.booked_by"
                  label="Name"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="appointmentForm.phone"
                  label="Phone"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="appointmentForm.department"
                  placeholder="Search.. ."
                  :loading="loadingDepartment"
                  :error-messages="errors.department"
                  :items="departmentOptions"
                  :search-input.sync="searchDepartment"
                  cache-items
                  hide-details="auto"
                  label="Department"
                  item-text="name"
                  outlined
                  dense
                  hide-no-data
                  return-object
                  @keyup.enter="fetchDepartments"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <p class="mt-5 OVERLINE">
              Vehicle Details
            </p>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="appointmentForm.plate_number"
                  :error-messages="errors.plate_number"
                  label="Plate Number"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="appointmentForm.description"
                  label="Details"
                  rows="8"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="appointmentForm.status"
                  :items="['Pending', 'Approved', 'Declined']"
                  hide-details="auto"
                  label="Status"
                  outlined
                  dense
                  hide-no-data
                  :error-messages="errors.status"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="8"
            sm="12"
          >
            <p class="mt-5 OVERLINE">
              Select Preferred Date:
            </p>
            <v-row>
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <v-date-picker
                  v-model="appointmentForm.booked_date"
                  color="primary"
                  @change="changeBookedDate"
                ></v-date-picker>
                <div>
                  <p class="mt-5 OVERLINE">
                    Select Preferred Time:
                  </p>
                  <template
                    v-for="(item, i) in timeOptions"
                  >
                    <v-btn
                      :key="i"
                      :disabled="bookedSlots.includes(item.text)"
                      color="primary"
                      :outlined="item.text !== selectedTime"
                      rounded
                      class="ma-1"
                      width="120"
                      @click="selectedTime = item.text; appointmentForm.booked_time = item.text"
                    >
                      {{ item.text }}
                    </v-btn>
                  </template>
                  <p
                    v-if="errors.booked_time"
                    class="error--text ma-5"
                    style="border-top: 2px solid #ff4c51;"
                  >
                    The appointment time is required.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="saveForm"
            >
              Save
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="$router.back()"
            >
              Back
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="resetForm"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import axios from '@axios'
import useAppointments from './useAppointments'
import router from '@/router'
import useDepartments from '../job-orders/useDepartments'
import useUIResolver from '../useUIResolver'
import store from '@/store'

const formInit = () => ({
  id: null,
  plate_number: '',
  booked_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
  booked_time: null,
  department: null,
  booked_by: null,
  phone: null,
  description: null,
  status: 'Pending',
})

export default {
  setup() {
    const errors = ref([])
    const acquisitionDatePicker = ref(false)
    const appointmentForm = ref(formInit())
    const { appointment, getAppointment } = useAppointments()
    const {
      departments: departmentOptions,
      getDepartments,
      searchDepartment,
      loading: loadingDepartment,
    } = useDepartments()

    const bookedSlots = ref([])

    const { resolveAppointmentStatus } = useUIResolver()

    const selectedTime = ref(null)
    const timeOptions = [
      { text: '8:00am' },
      { text: '8:30am' },
      { text: '9:00am' },
      { text: '9:30am' },
      { text: '10:00am' },
      { text: '10:30am' },
      { text: '11:00am' },
      { text: '11:30am' },
      { text: '12:00pm' },
      { text: '12:30pm' },
      { text: '1:00pm' },
      { text: '1:30pm' },
      { text: '2:00pm' },
      { text: '2:30pm' },
      { text: '3:00pm' },
      { text: '3:30pm' },
    ]

    const resetForm = () => {
      appointmentForm.value = formInit()
      selectedTime.value = null
    }

    if (router.currentRoute.params.id) {
      // fetch appointment from the server
      getAppointment(router.currentRoute.params.id).then(response => {
        const { data } = response
        appointmentForm.value = data

        const department = {
          name: data.department_name,
          id: data.department_id,
        }
        departmentOptions.value = data.department_id ? [department] : []
        appointmentForm.value.department = department
        selectedTime.value = data.booked_time
      })
    }

    const fetchDepartments = () => {
      getDepartments({ search: searchDepartment.value })
    }

    const changeBookedDate = val => {
      axios.get(`/appointments/check-availability/${val}`).then(r => {
        bookedSlots.value = r.data.map(o => o.booked_time)
      })
    }

    const saveForm = () => {
      if (appointmentForm.value.id) {
        // update
        axios
          .put(`/appointments/${appointmentForm.value.id}`, appointmentForm.value)
          .then(() => {
            store.dispatch('app/getPendingAppointmentsCount')
            router.back()
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
          })
      } else {
        // new
        axios
          .post('/appointments', appointmentForm.value)
          .then(() => {
            store.dispatch('app/getPendingAppointmentsCount')
            router.back()
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
          })
      }
    }

    return {
      // variables
      errors,
      selectedTime,
      timeOptions,
      bookedSlots,

      // functions
      saveForm,
      changeBookedDate,

      // useAppointments
      appointment,
      getAppointment,

      // useDepartments
      departmentOptions,
      fetchDepartments,
      searchDepartment,
      loadingDepartment,

      // useUIResolver
      resolveAppointmentStatus,

      acquisitionDatePicker,
      appointmentForm,

      resetForm,

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
